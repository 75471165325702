import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./index.css"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <main role="main" className="inner cover">
      <img src="/images/logo-home.png" className="home-logo" alt="blubun"/>
      <p className="lead">
        An independent game development venture<br/><br/>
        Coming Soon
      </p>
    </main>
  </Layout>
)

export default IndexPage
